import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Grid, Link, Margin } from "@components/atoms"
import { GridItem, Tags } from "@components/molecules"
import { Tag } from "@components/molecules/Tags"
import { Newsletter, TransitionMask } from "@components/organisms"
import { PageProps } from "@types"
import { palette } from "@theme"
import { useHistory } from "@utils"

const IndexPage = ({
  data: {
    sanityPage: {
      backgroundColor,
      backgroundImage,
      gridItems,
      extraHomeFields,
    },
  },
}: PageProps) => {
  const { visitedUrls } = useHistory()

  if (!extraHomeFields) return <></>

  const { tags } = extraHomeFields

  const isFullBleed =
    (gridItems[0]?.layout && gridItems[0]?.layout?.fullBleed) ?? false

  return (
    <Main
      backgroundColor={backgroundColor?.hex ?? "#fef3d9"}
      backgroundImage={backgroundImage?.asset.url ?? undefined}
    >
      <Margin>
        <StyledGrid wideMobileRows {...{ isFullBleed }}>
          {gridItems
            .filter(p => !!p)
            .map((item, i) => (
              <GridItem
                key={item._key}
                {...item}
                zIndex={9999 - i}
                pageBackgroundColor={backgroundColor.hex}
              >
                <Title>
                  <Link
                    className={
                      visitedUrls.includes(`/${item.post?.slug.current}`)
                        ? "visited"
                        : "derp"
                    }
                    to={`/${item.post?.slug.current}`}
                  >
                    {item.post?.title}
                  </Link>
                </Title>
              </GridItem>
            ))}
        </StyledGrid>
      </Margin>
      <StyledMargin>
        <Tags {...{ tags }}>
          <Tag color="#ffffff" client={false}>
            <Link to="/tags">Browse all tags</Link>
          </Tag>
        </Tags>
      </StyledMargin>
      <Newsletter />
      <TransitionMask />
    </Main>
  )
}

const Main = styled.main<{ backgroundColor: string; backgroundImage?: string }>`
  position: relative;
  background-color: ${props => props.backgroundColor ?? "#fef3d9"};
  ${props =>
    props.backgroundImage && `background-image: url(${props.backgroundImage});`}
`

const StyledMargin = styled(props => <Margin {...props} />)`
  position: relative;
  z-index: 9999;
  margin-top: -15vw;
`

const StyledGrid = styled(props => <Grid {...props} />)`
  @media only screen and (min-width: 768px) {
    padding: 0 0 90px 0;
    grid-row-gap: 7.32vw;
  }
  @media only screen and (min-width: 1024px) {
    padding: 0 0 120px 0;
  }
`

const Title = styled.h2`
  text-align: center;
  color: ${palette.black};
  a {
    position: relative;
    color: inherit;

    &:visited {
      text-decoration: underline;
    }

    &:visited *:after {
      content: " ✓";
      font-size: 1em;
    }
  }
  line-height: 1.1em;
  margin: 0.5em 0;
  font-size: 7vw;
  @media only screen and (min-width: 768px) {
    font-size: 2.3vw;
  }
`

export const query = graphql`
  query HomePageQuery {
    sanityPage(slug: { current: { eq: "home" } }) {
      ...pageFields
      extraHomeFields {
        tags {
          tag
          tagSet {
            category
            color {
              hex
            }
          }
        }
      }
    }
  }
`

export default IndexPage
